import React, { useState } from "react"
import Axios from "axios"
import Recaptcha from "react-recaptcha"
import Button from "react-bootstrap/Button"

import Message from "../../../Message"
import Icon from "../../../Icon"
import Feedback from "../../../Forms/Feedback"

const API = process.env.API
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const Questions = ({ questions, title }) => {
  const [formData, setFormData] = useState({
    questions: questions,
    competition: title,
  })
  const [validationErrors, setValidationErrors] = useState({})
  const [message, setMessage] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChangeEvent = (e) => {
    e.persist()
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleUpdateQuestion = (id, e) => {
    const newQuestions = formData.questions.map((question) => {
      if (id === question.id) {
        return {
          id: id,
          question: e.target.name,
          answer: e.target.value,
        }
      } else {
        return question
      }
    })
    setFormData({
      ...formData,
      questions: newQuestions,
    })
  }

  const resetRecaptcha = () => {
    const newFormData = formData
    delete newFormData.reCaptcha
    setFormData(newFormData)
    recaptchaInstance.reset()
  }

  const handleReCaptcha = (response) => {
    setFormData((prevState) => ({ ...prevState, reCaptcha: response }))
  }

  const handleCheckboxChange = (event) => {
    const { name } = event.target
    setFormData({
      ...formData,
      [name]: Number(!formData[name]),
    })
  }

  const validateRecaptcha = () => {
    let ok = true
    setValidationErrors({})
    if (!formData.reCaptcha) {
      setValidationErrors({
        ...validationErrors,
        reCaptcha: "Please verify you are a human",
      })
      setIsSubmitting(false)
      ok = false
    }
    return ok
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    setMessage({})
    const isValid = validateRecaptcha()
    if (isValid) {
      Axios.post(`${API}/forms/competition`, formData)
        .then(() => {
          setIsSubmitting(false)
          setMessage({
            type: "success",
            text: "Your answers have been sent",
          })
          resetRecaptcha()
        })
        .catch(() => {
          setIsSubmitting(false)
          setMessage({
            type: "danger",
            text: "Server Error, please try again later",
          })
          resetRecaptcha()
        })
    }
  }

  const questionsList = questions.map((question) => (
    <div className="row" key={question.id}>
      <div className="col">
        <div className="form-group">
          <label htmlFor={`question${question.id}`}>{question.question}</label>
          <input
            onChange={(e) => handleUpdateQuestion(question.id, e)}
            name={question.question}
            className="form-control"
            id={`question${question.id}`}
            aria-label={`Question ${question.id}`}
          />
        </div>
      </div>
    </div>
  ))

  return (
    <>
      <h1>Enter Here:</h1>
      {questionsList}
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="name">Full Name:</label>
            <input
              onChange={handleChangeEvent}
              name="name"
              className="form-control"
              id="name"
              aria-label="Your Name"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input
              onChange={handleChangeEvent}
              name="address"
              className="form-control"
              id="address"
              aria-label="Your Address"
            />
            <small className="form-text text-muted">Not for publication</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              onChange={handleChangeEvent}
              name="phone"
              className="form-control"
              id="phone"
              aria-label="Your Phone Number"
            />
            <small className="form-text text-muted">Not for publication</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="email">E-Mail:</label>
            <input
              onChange={handleChangeEvent}
              name="email"
              className="form-control"
              id="email"
              aria-label="Your Email Address"
            />
            <small className="form-text text-muted">Not for publication</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="pickupLocation">
              Where did you pick up your Bundy Book?:
            </label>
            <input
              onChange={handleChangeEvent}
              name="pickupLocation"
              className="form-control"
              id="pickupLocation"
              aria-label="Where did you pick up your Bundy Book"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <div className="form-check">
              <input
                onChange={handleCheckboxChange}
                name="bundyBookExtra"
                className="form-check-input"
                type="checkbox"
                id="bundyBookExtra"
                aria-label="Bundy Book Extra"
              />
              <label htmlFor="bundyBookExtra" className="form-check-label">
                Subscribe to our free Bundy Book Extra with competitions,
                special offers, happenings and lots more
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <Recaptcha
          ref={(e) => (recaptchaInstance = e)}
          sitekey={recaptchaKey}
          render="explicit"
          onloadCallback={() => {}}
          verifyCallback={(response) => {
            handleReCaptcha(response)
          }}
        />
        <Feedback message={validationErrors.reCaptcha} />
      </div>
      <Message type={message.type} message={message.text} />
      {isSubmitting ? (
        <Button type="submit" disabled bsPrefix="cln">
          Sending <Icon prefix="fas" name="spinner" spin={true} />
        </Button>
      ) : (
        <Button type="submit" onClick={handleSubmit} bsPrefix="cln">
          Send
        </Button>
      )}
    </>
  )
}

export default Questions
