import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Content from "../components/Layout/Content"
import Questions from "../components/Competitions/Competition/Questions"

const Competition = ({ location, pageContext }) => {
  const { competition } = pageContext

  return (
    <Layout location={location}>
      <SEO title={competition.title} description={competition.description} />
      <div className="row">
        <div className="col">
          <div className="row">
            {competition.closed === 1 ? (
              <div className="col-12">
                <h1>{competition.title}</h1>
                <Content content={competition.content} />
              </div>
            ) : (
              <>
                <div className="col-md-8">
                  <h1>{competition.title}</h1>
                  <Content content={competition.content} />
                </div>
                <div className="col-md-4 questions">
                  <Questions questions={competition.questions} title={competition.title} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Competition
